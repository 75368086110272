import React from 'react';
import { Link } from 'react-router-dom';
import Offcanvas from 'react-bootstrap/Offcanvas'
import { FaFacebookF, FaYoutube ,FaLinkedin, FaInstagram, FaEnvelope, FaPhone } from "react-icons/fa";


const OffcanvasMenu = ({ offcanvasShow, offcanvasHide }) => {
    return (
        <Offcanvas show={offcanvasShow} onHide={offcanvasHide} placement="end" className="header-offcanvasmenu">
            <Offcanvas.Header closeButton></Offcanvas.Header>
            <Offcanvas.Body>
                <div className="row ">
                    <div className="col-lg-5 col-xl-6">
                        <ul className="main-navigation list-unstyled">
                            <li><a href="#quehacemos">Que Hacemos</a></li>
                            <li><a href="#comolohacemos">Como lo Hacemos</a></li>
                            <li><a href="#porquelohacemos">Por qué lo Hacemos</a></li>
                            <li><Link to={process.env.PUBLIC_URL + "/contact"}>Quieres una Hacker house</Link></li>
                        </ul>
                    </div>
                    <div className="col-lg-7 col-xl-6">
                        <div className="contact-info-wrap">
                            <div className="contact-inner">

                                <address className="address">
                                    <span className="title">Contáctanos.</span>
                                    <a href="tel:+56956239715" className="tel"><FaPhone /> (+569) 5623 9715</a>
                                    <a href="mailto:contacto@9housefoundation.org" className="tel"><FaEnvelope /> contacto@9housefoundation.org</a>
                                </address>
                            </div>
                            <div className="contact-inner">
                                <h5 className="title">Encuentranos en</h5>
                                <div className="contact-social-share">
                                    <ul className="social-share list-unstyled">
                                        <li><a target='_blank' rel="noopener noreferrer" href="https://www.instagram.com/hacker9house/?igshid=MmJiY2I4NDBkZg%3D%3D"><FaInstagram /></a></li>
                                        <li><a target='_blank' rel="noopener noreferrer" href="https://www.facebook.com/"><FaFacebookF /></a></li>
                                        <li><a target='_blank' rel="noopener noreferrer" href="https://www.linkedin.com/company/hacker9house/"><FaLinkedin /></a></li>
                                        <li><a target='_blank' rel="noopener noreferrer" href="https://www.youtube.com/@Fundacion9House"><FaYoutube /></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Offcanvas.Body>
        </Offcanvas>
    )
}

export default OffcanvasMenu;
