import React from 'react';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import ScrollToTop from './component/scrollToTop/ScrollToTop';
import 'bootstrap/dist/css/bootstrap.min.css';

import ComingSoon from './pages/ComingSoon';
import HomeStartup from './pages/HomeStartup';
import Contact from './pages/Contact'

// Css Import
import './assets/scss/app.scss';


const App = () => {
  return (
    <Router>
		<ScrollToTop>
			<Routes>
				<Route path={process.env.PUBLIC_URL + "/"} element={<ComingSoon />}/>
				<Route path={process.env.PUBLIC_URL + "/homeStartup"} element={<HomeStartup />}/>
				<Route path={process.env.PUBLIC_URL + "/contact"} element={<Contact />}/>

			</Routes>
		</ScrollToTop>
    </Router>
  )
}

export default App;
