import React from 'react';
import { FaFacebookF, FaYoutube ,FaLinkedin, FaInstagram } from "react-icons/fa";
import LogoFooter from '../../elements/logo/Logo-footer';
import { Link } from 'react-router-dom';

const FooterOne = ({ parentClass }) => {

    return (
        <footer className={`footer-area ${parentClass}, splash-footer`}>
            <div className="container">
                <div className="footer-main">
                    <div className="row">
                        <div className="col-xl-6 col-lg-5">
                            <div className="footer-widget">
                                <div className="footer-newsletter">
                                <LogoFooter limage="/images/logo.svg"
                                dimage="/images/logo-3.svg"/>
                                    <p className='mt-5'>Fundación sin fines de lucro que busca dar oportunidades laborales a través de la educación.</p>
                                </div>
                            </div>
                        </div>
                        <div className="offset-xl-2 col-xl-4 col-lg-7">
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="footer-widget">
                                        <h6 className="widget-title">Fundación</h6>
                                        <div className="footer-menu-link">
                                            <ul className="list-unstyled">
                                                <li><a href="#quehacemos">Que hacemos</a></li>
                                                <li> <a href="#comolohacemos">Como lo hacemos</a></li>
                                                <li><a href="#porquelohacemos">Por qué lo hacemos</a></li>
                                                <li><Link to={process.env.PUBLIC_URL + "/contact"}>Quieres una Hacker house</Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-bottom">
                    <div className="row align-items-center">
                        <div className="col-lg-4">
                            <div className="footer-copyright">
                                <span className="copyright-text">© {new Date().getFullYear()}. Todos los derechos reservados. <a href="https://9housefoundation.org/">9House</a>.</span>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <ul className="footer-social list-unstyled">
                                <li><a target='_blank' rel="noopener noreferrer" href="https://www.instagram.com/hacker9house/?igshid=MmJiY2I4NDBkZg%3D%3D"><FaInstagram /></a></li>
                                <li><a target='_blank' rel="noopener noreferrer" href="https://www.facebook.com/"><FaFacebookF /></a></li>
                                <li><a target='_blank' rel="noopener noreferrer" href="https://www.linkedin.com/company/hacker9house/"><FaLinkedin /></a></li>
                                <li><a target='_blank' rel="noopener noreferrer" href="https://www.youtube.com/@Fundacion9House"><FaYoutube /></a></li>
                            </ul>
                        </div>
                        <div className="col-lg-4">
                            <div className="footer-bottom-link">
                                <ul className="list-unstyled">
                                    <li><a href="#inicio">Ir al inicio</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default FooterOne;