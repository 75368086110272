import React from 'react';
import { Link } from 'react-router-dom';


const Nav = () => {
    return (
        <nav className="mainmenu-nav">
            <ul className="mainmenu">
                <li>
                    <a href="#quehacemos">QUE HACEMOS</a>
                </li>
                <li>
                    <a href="#comolohacemos">COMO LO HACEMOS</a>
                </li>
                <li>
                    <a href="#porquelohacemos">POR QUÉ LO HACEMOS</a>
                </li>
                <li><Link to={process.env.PUBLIC_URL + "/contact"}>QUIERES UNA HACKER HOUSE</Link></li>
            </ul>
        </nav>
    )
}

export default Nav;