import React from 'react'
import PropTypes from "prop-types";


const SEO = ( {title} ) => {
    return (
        <>
            <meta charSet="utf-8" />
            <title>9House | {title}</title>
            <meta name="robots" content="noindex, follow" />
            <meta name="description" content="9House oportunidades laborales para jóvenes a través de la enseñanza de programación y materias digitales." />
            <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        </>
    )
}
SEO.propTypes = {
    title: PropTypes.string
};

export default SEO;