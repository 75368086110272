import React from 'react';
import TeamData from "../../data/team/TeamData.json";

const allData = TeamData;

const TeamOne = () => {
    return (
        <div className="section section-padding bg-color-dark pb--70 pb_lg--20 pb_md--0">
            <div className="container">
                <div className="section-heading heading-light">
                    <span className="subtitle">TEAM</span>
                    <h2 className="title mb--50 position-rel">
                        Soñadores
                        <img className='title-right-line' src={process.env.PUBLIC_URL + "/images/others/line-5.png"} alt="line" />
                    </h2>
                    <p>Somos simplemente un grupo que tienen en común la motivación de AYUDAR.</p>
                </div>
                <div className="row">
                    {allData.map((data) => (
                        <div className="col-xl-4 col-sm-6" key={data.id}>
                            <div className="team-grid">
                                <div className="thumbnail">
                                    <a target='_blank' rel="noopener noreferrer" href={`${data.social.linkedin}`}>
                                        <img src={process.env.PUBLIC_URL + data.thumb} alt={data.title} />
                                    </a>
                                </div>
                                <div className="content">
                                    <h4 className="title">
                                        <a target='_blank' rel="noopener noreferrer" href={`${data.social.linkedin}`}>
                                            {data.title}
                                        </a>
                                    </h4>
                                    {/*<span className="designation" dangerouslySetInnerHTML={{__html: data.designation}}></span>*/}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>

    )
}

export default TeamOne;