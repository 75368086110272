import React from 'react';
import SEO from '../common/SEO';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import { Link } from 'react-router-dom';
import Countdown from 'react-countdown';
import FormOne from '../component/contact/FormOne';
import ProcessOne from '../component/process/ProcessOne';
import FooterOne from '../common/footer/FooterOne';
import HomeStartup from '../pages/HomeStartup'


const ComingSoon = () => {


    const SetCountdownTime = "2023-05-25T18:00:00";

    const CountDownRender = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
            return <HomeStartup />;
        } else {
            return (
                <>
                    <SEO title="Proximamente" />
                    <ColorSwitcher />
                    <main className="main-wrapper" id='inicio'>
                        <div className="coming-soon-area onepage-screen-area">
                            <div className="container">
                                <div className="row align-items-center justify-content-center">
                                    <div className="col-12">
                                        <div className="site-logo">
                                            <Link to={process.env.PUBLIC_URL + "/"} className="logo-light"><img src={process.env.PUBLIC_URL + "/images/logo-2.svg"} alt="Logo" /></Link>
                                            <Link to={process.env.PUBLIC_URL + "/"} className="logo-dark"><img src={process.env.PUBLIC_URL + "/images/logo-3.svg"} alt="Logo" /></Link>
                                        </div>
                                    </div>
                                    <div className="col-xl-6 col-lg-8">
                                        <div className="content">
                                            <h2 className="title">Nuestro Sitio web está en camino</h2>
                                            <p>Tecnología para generar oportunidades sostenibles a jóvenes.</p>
                                            <div className="countdown">
                                                <div className="countdown-section">
                                                    <span className="countdown-number">{days}</span>
                                                    <span className="countdown-unit">Dias</span>
                                                </div>
                                                <div className="countdown-section">
                                                    <span className="countdown-number">{hours}</span>
                                                    <span className="countdown-unit">Horas</span>
                                                </div>
                                                <div className="countdown-section">
                                                    <span className="countdown-number">{minutes}</span>
                                                    <span className="countdown-unit">Minutos</span>
                                                </div>
                                                <div className="countdown-section">
                                                    <span className="countdown-number">{seconds}</span>
                                                    <span className="countdown-unit">Segundos</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-xl-5">
                                        <div className="banner-form">
                                            <div className="contact-form-box shadow-box">
                                                <h3 className="title">¿Quieres saber más? Contáctanos</h3>
                                                <FormOne />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <ul className="shape-group-8 list-unstyled">
                                <li className="shape shape-2">
                                    <img src={process.env.PUBLIC_URL + "/images/others/bubble-29.png"} alt="Bubble" />
                                </li>
                                <li className="shape shape-3">
                                    <img src={process.env.PUBLIC_URL + "/images/others/line-4.png"} alt="Line" />
                                </li>
                            </ul>
                        </div>

                        <div className='call-to-action-area'>
                            <ProcessOne />
                            <FooterOne />
                        </div>
                    </main>
                </>

            )
        }
    }


    return (
        <Countdown className='mb-5' date={SetCountdownTime} zeroPadTime={3} renderer={CountDownRender}></Countdown>
    )
}

export default ComingSoon;