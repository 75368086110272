import React from 'react';
import { FaFacebookF, FaInstagram, FaYoutube, FaLinkedinIn } from "react-icons/fa";
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { FaPlay } from 'react-icons/fa';
import FsLightbox from 'fslightbox-react';


const BannerFour = () => {
    const [toggler, setToggler] = useState(false);
    return (
        <>
            <div id='inicio' className="banner banner-style-4">
                <div className="container">
                    <div className="banner-content">
                        <h1 className="title">Se parte de una <span className='color-amarillo' style={{ display: 'inline' }}>nueva forma de educar...</span></h1>
                        <p>Donde las diferencias y<br /> las barreras no existen</p>
                        <div>
                            <Link to={process.env.PUBLIC_URL + "/contact"} className="axil-btn btn-fill-primary btn-large">Quiero tener una hacker house</Link>
                        </div>
                    </div>
                    <div className="banner-thumbnail">
                        <div className="large-thumb">

                            <div className="about-expert">
                                <div className="thumbnail">
                                    <img src={process.env.PUBLIC_URL + "/images/banner/bg-video-banner.jpg"} alt="Thumbnail" />
                                    <div className="popup-video">
                                        <button className="play-btn" onClick={() => setToggler(!toggler)}><FaPlay /></button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="banner-social">
                        <div className="border-line" />
                        <ul className="list-unstyled social-icon">
                            <li><a href="https://www.instagram.com/hacker9house/?igshid=MmJiY2I4NDBkZg%3D%3D/"><FaInstagram /></a></li>
                            <li><a href="https://www.facebook.com/"><FaFacebookF /></a></li>
                            <li><a href="https://www.linkedin.com/company/hacker9house/"><FaLinkedinIn /></a></li>
                            <li><a href="https://www.youtube.com/@Fundacion9House"><FaYoutube /></a></li>
                        </ul>
                    </div>
                </div>
                <ul className="list-unstyled shape-group-19">
                    <li className="shape shape-1">
                        <img src={process.env.PUBLIC_URL + "/images/others/bubble-29.png"} alt="Bubble" />
                    </li>
                    <li className="shape shape-2">
                        <img src={process.env.PUBLIC_URL + "/images/others/line-5.png"} alt="line" />
                    </li>
                    <li className="shape shape-3">
                        <img src={process.env.PUBLIC_URL + "/images/others/line-5.png"} alt="line" />
                    </li>
                </ul>
            </div>
            <FsLightbox toggler={toggler} sources={['https://www.youtube.com/watch?v=pi0rYWgTGfQ&ab_channel=Fundaci%C3%B3n9House']} />
        </>
    )
}


export default BannerFour;