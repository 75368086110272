import React from 'react';
import SectionTitle from '../../elements/section-title/SectionTitle';
import BrandItem from './BrandItem';
import { Link } from 'react-router-dom';


const BrandOne = () => {
    return (
        <div className="section section-padding-2 bg-color-light">
        <div className="container">
            <SectionTitle 
                subtitle="JUNTOS POR UN MEJOR FUTURO"
                title="Alianzas"
                description="Agradecemos a todos nuestro colaboradores que tienen nuestro mismo propósito de ayudar y son una pieza clave para nuestra misión."
                textAlignment=""
                textColor=""
            />
            <div className='row mb-5 d-flex justify-content-center'>
               <BrandItem />
            </div>
            <div className='row position-rel'>
                <div className='col-xl-12 text-center mt-5'>
                <Link to={process.env.PUBLIC_URL + `/contact`} className="axil-btn btn-large btn-fill-primary">¿Deseas unirte?</Link>
                <img className='title-right-line-btn' src={process.env.PUBLIC_URL + "/images/others/line-5.png"} alt="line" />
                </div>
            </div>
        </div>
    </div>
    )
}

export default BrandOne;