import React from 'react';
import SEO from '../common/SEO';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import BreadCrumbOne from '../elements/breadcrumb/BreadCrumbOne';
import FormOne from '../component/contact/FormOne';



const Contact = () => {
    return (
        <>
            <SEO title="Contacto" />
            <ColorSwitcher />
            <main className="main-wrapper">
                <HeaderOne />
                <BreadCrumbOne
                    title="Contacto"
                    page="Contacto"
                />

                <div className="section section-padding">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-5 col-lg-6">
                                <div className="contact-form-box shadow-box mb--30">
                                    <h3 className="title">¿Quieres saber más? Contáctanos</h3>
                                    <FormOne />
                                </div>
                            </div>
                            <div className="col-xl-5 col-lg-6 offset-xl-1">
                                <div className="contact-info mb--100 mb_md--30 mt_md--0 mt--150">
                                    <h4 className="title">Teléfono</h4>
                                    <p>Llámanos de Lunes a Viernes, 09:00 am a 6:00 pm</p>
                                    <h4 className="phone-number"><a href="tel:+56956239715">(+569) 5623 9715</a></h4>
                                </div>
                                <div className="contact-info mb--30">
                                    <h4 className="title">Email</h4>
                                    <p>Escríbenos te responderemos a la brevedad</p>
                                    <h4 className="phone-number"><a href="mailto:contacto@9housefoundation.org">contacto@9housefoundation.org</a></h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ul className="list-unstyled shape-group-12">
                        <li className="shape shape-3"><img src={process.env.PUBLIC_URL + "/images/others/circle-3.png"} alt="Circle" /></li>
                    </ul>
                </div>

                <FooterOne parentClass="pt--150 pt_lg--100 pt_md--80 pt_sm--60" />
            </main>
        </>
    )
}

export default Contact;