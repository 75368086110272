import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderTwo from '../common/header/HeaderTwo';
import SEO from '../common/SEO';
import BannerFour from '../component/banner/BannerFour';
import AboutFive from '../component/about/AboutFive';
import BrandOne from '../component/brand/BrandOne';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import SectionTitle from '../elements/section-title/SectionTitle';
import ServicePropOne from '../component/service/ServicePropOne';
import VideoTwo from '../component/video/VideoTwo';
import TeamOne from '../component/team/TeamOne';
import ProjectFour from '../component/project/ProjectFour';

const HomeStartup = () => {

    return (
        <>
            <SEO title="Fundación 9House" />
            <ColorSwitcher />
            <main className="main-wrapper">
                <HeaderTwo />
                <BannerFour />
                <ProjectFour/>
                <AboutFive />
                <div className="section section-padding">
                    <div className="container">
                        <SectionTitle
                            subtitle="INTÉGRATE"
                            title="¿Te gustaría ser parte?"
                            description=""
                            textAlignment="heading-left"
                            textColor=""
                        />
                        <div className="row">
                            <ServicePropOne colSize="col-lg-4 col-md-6" serviceStyle="service-style-2" itemShow="3" marginTop="no" />
                        </div>
                    </div>
                </div>
                <VideoTwo />
                <TeamOne />
                <BrandOne />
                {/*<TestimonialOne />*/}
                <FooterOne parentClass="pt--150 pt_lg--100 pt_md--80 pt_sm--60" />
            </main>
        </>
    )
}

export default HomeStartup;

