import React from 'react';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

const ProjectFour = () => {
    var placementRight = String;
    var placementLeft = String;

        if (window.innerWidth < 767) { // Cambia el placement para pantallas más pequeñas (menos de 768px)
          placementLeft = 'top';
          placementRight = 'top';
        } else {
          placementLeft = 'left'; // Vuelve al placement inicial para pantallas más grandes
          placementRight = 'right'; // Vuelve al placement inicial para pantallas más grandes
        }

    return (
        <div className='bg-color-dark'>
            <div id='quehacemos' className="section section-padding-equal pb--0 bg-color-dark">
                <div className="container">
                    <div className="project-add-banner">
                        <div className="content">
                            <span className='subtitle'>¿QUE HACEMOS?</span>
                            <h3 className="title">Abrimos puertas para el futuro laboral de los jovenes</h3>
                            <p>Somos una organización sin fines de lucro dedicada a proporcionar oportunidades laborales en la vida de muchos jóvenes en el país, a través de la enseñanza en etapas tempranas de programación y otras materias digitales.</p>

                        </div>
                        <div className="thumbnail">
                            <img src={process.env.PUBLIC_URL + "/images/project/mobile-mockup.png"} alt="Mockup" />
                        </div>
                    </div>

                </div>
            </div>

            <div id='comolohacemos' className="section section-padding-equal bg-color-dark">
                <div className="container">
                    <div className='row'>
                        <div className='col-xl-12 text-center section-heading'>
                            <div className='subtitle'>METODOLOGÍA</div>
                            <h2 className='title position-rel' style={{ color: '#ffffff' }}>¿Cómo lo hacemos?
                                <img className='title-right-line' src={process.env.PUBLIC_URL + "/images/others/line-5.png"} alt="line" />
                            </h2>
                            <p style={{ width: '67%' }}>A través de nuestras HACKER HOUSES, ocupamos una metodología que hemos construido basado en 4 engranajes, que trabajando en conjunto logran el éxito. Generando oportunidades laborales rentables y sostenibles.
                                <br />
                                <br /> Estas houses son implementadas en distintos espacios para jóvenes entre 14 y 18 años (colegios, municipalidades, entre otras).</p>


                            <div className='hacker-method'>
                                <img className='mt-3 contenedor-hacker' src={process.env.PUBLIC_URL + "/images/project/project-0.png"} alt="Container 9House" />
                                <ul>
                                    <li>
                                        <OverlayTrigger
                                            key="academia"
                                            placement={placementRight}
                                            overlay={
                                                <Tooltip id="academia">
                                                    <div className='container-tooltip'>
                                                        <div className='titleCTool'>
                                                            <img src={process.env.PUBLIC_URL + "/images/icon/icon-1.png"} alt='icon'/>
                                                            <h5>Academia</h5>
                                                        </div>

                                                        <p>Utilizamos una malla de contenidos de programación que utiliza las principales tecnologías actualizadas y demandadas en los empresas actuales. Y enseñamos con el metodo teorico-practico, en 3 niveles.</p>
                                                    </div>
                                                </Tooltip>}>
                                            <Button variant="secondary">
                                                <div>Academia</div>
                                                <img src={process.env.PUBLIC_URL + "/images/icon/line-dot-academia.png"} alt='icon'/>
                                            </Button>
                                        </OverlayTrigger>
                                    </li>
                                    <li>
                                        <OverlayTrigger
                                            key="infraestructura"
                                            placement={placementLeft}
                                            overlay={
                                                <Tooltip id="infraestructura">
                                                    <div className='container-tooltip'>
                                                        <div className='titleCTool'>
                                                            <img src={process.env.PUBLIC_URL + "/images/icon/icon-1.png"} alt='icon'/>
                                                            <h5>Infraestructura</h5>
                                                        </div>

                                                        <p>Es más que una estructura, es un modulo que insertamos en la ubicación definida, que tiene altos estándares de diseño y equipada con las herramientas necesarias para que los jóvenes logren una capacitación de calidad, dejen los problemas del entorno y sueñen con su futuro.</p>
                                                    </div>
                                                </Tooltip>}>
                                            <Button variant="secondary">
                                                <div>Infraestructura</div>
                                                <img src={process.env.PUBLIC_URL + "/images/icon/line-dot-infraestructura.png"} alt='icon'/>
                                            </Button>
                                        </OverlayTrigger>
                                    </li>
                                    <li>
                                        <OverlayTrigger
                                            key="infraestructura"
                                            placement={placementLeft}
                                            overlay={
                                                <Tooltip id="infraestructura">
                                                    <div className='container-tooltip'>
                                                        <div className='titleCTool'>
                                                            <img src={process.env.PUBLIC_URL + "/images/icon/icon-1.png"} alt='icon'/>
                                                            <h5>Valores</h5>
                                                        </div>

                                                        <p>Sabemos que las empresas actualmente están privilegiando las habilidades blandas por sobre los conocimientos técnicos y también queremos fortalecer la inteligencia emocional, por lo que dedicaremos un gran esfuerzo en generar valores a nuestros jóvenes.</p>
                                                    </div>
                                                </Tooltip>}>
                                            <Button variant="secondary">
                                                <div>Valores</div>
                                            </Button>
                                        </OverlayTrigger>
                                    </li>
                                    <li>
                                        <OverlayTrigger
                                            key="networking"
                                            placement={placementRight}
                                            overlay={
                                                <Tooltip id="networking">
                                                    <div className='container-tooltip'>
                                                        <div className='titleCTool'>
                                                            <img src={process.env.PUBLIC_URL + "/images/icon/icon-1.png"} alt='icon'/>
                                                            <h5>Networking</h5>
                                                        </div>

                                                        <p>No solo nos quedaremos en enseñarles, sino tambien nos haremos cargo de conseguirles una oferta laboral y apoyarlos en sus primeras etapas.</p>
                                                    </div>
                                                </Tooltip>}>
                                            <Button variant="secondary">
                                                <div>Networking</div>
                                                <img src={process.env.PUBLIC_URL + "/images/icon/line-dot-networking.png"} alt='icon'/>
                                            </Button>
                                        </OverlayTrigger>
                                    </li>
                                    <li>
                                        <OverlayTrigger
                                            key="engagement"
                                            placement={placementLeft}
                                            overlay={
                                                <Tooltip id="engagement">
                                                    <div className='container-tooltip'>
                                                        <div className='titleCTool'>
                                                            <img src={process.env.PUBLIC_URL + "/images/icon/icon-1.png"} alt='icon'/>
                                                            <h5>Engagement</h5>
                                                        </div>

                                                        <p>Utilizamos los metodos de las empresas para activar y retener a sus clientes, con el objetivo de mantener a los jovenes y sus familias motivados e inspirados en el tiempo. Tenemos una APP que articula distintas actividades como un avatar evolutivo, actividades de gamification, beneficios, charlas, trabajos bonificados, entre otros.</p>
                                                    </div>
                                                </Tooltip>}>
                                            <Button variant="secondary">
                                                <div>Engagement</div>
                                                <img src={process.env.PUBLIC_URL + "/images/icon/line-dot-engagement.png"} alt='icon'/>
                                            </Button>
                                        </OverlayTrigger>
                                    </li>
                                </ul>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProjectFour;