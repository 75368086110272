import React from 'react';

const AboutFive = () => {
    return (
        <div id='porquelohacemos' className="section-padding-equal">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div className="about-team">
                            <div className="section-heading heading-left">
                                <span className="subtitle">NUESTRA MOTIVACIÓN</span>
                                <h2>¿Por qué lo hacemos?</h2>
                                <p>Creemos que la vida de muchos jóvenes son complejas, tanto por las oportunidades que tienen o por las barreras del entorno para cumplir sus sueños.</p>

                                <p>Donde al terminar su época escolar y salir al mundo laboral, terminan trabajando en espacios no afines a sus gustos y en muchos casos en sueldos mínimos, generando toda una vida sin pasión, con crisis económicas, con depresiones, suicidios, delincuencia y otros.</p>

                                <p>Creemos que a través de nuestra metodología Hacker House y el boom tecnológico, podemos apoyar a muchos jóvenes y nos apasiona esta idea!</p>

                                <div className='motivation-line'>
                                    <img src={process.env.PUBLIC_URL + "/images/others/line-4.png"} alt="line" />
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="col-lg-6">
                        <div className="about-team">
                            <div className="thumbnail">
                                <img className='ourMotive' src={process.env.PUBLIC_URL + "/images/about/about-2.png"} alt="thumbnail" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutFive;