import React from 'react';

const Data = [
    {
        image: "/images/brand/brand-1.png",
        web: "https://www.wingsoft.com/"
    },
    {
        image: "/images/brand/brand-2.png",
        web: "https://ocularsolution.com/"
    },
    {
        image: "/images/brand/brand-3.png",
        web: "https://ocularsolution.com/"
    },
    {
        image: "/images/brand/brand-4.png",
        web: "https://veggo.cl/"
    }
]


const BrandItem = () => {
    return (
        <>
            {Data.map((data, index) => (
                <div className="col-lg-3 col-6" key={index}>
                    <div className="brand-grid">
                        <a target='_blank' rel="noopener noreferrer" href={`${data.web}`}>
                        <img src={process.env.PUBLIC_URL + data.image} alt="Brand" />
                        </a>
                    </div>
                </div>
            ))}
        </>
    )
}

export default BrandItem;